<template>
    <label class="command-line">
        <template v-for="line, x in computedLines">
            <div v-if="typeof line == 'string'" class="line" :class="[activeLine == x ? 'active' : '', typingLine == x ? 'typing' : '', done.includes(x) ? 'done' : '']">
                <span class="placeholder">&nbsp;</span>
                <span class="char" v-for="char in line" v-html="char == ' ' ? '&nbsp;' : char"/>
                <div class="cursor"></div>
            </div>

            <a :href="line[1]" v-else class="line link" :class="[activeLine == x ? 'active' : '', typingLine == x ? 'typing' : '', done.includes(x) ? 'done' : '']">
                <span class="placeholder">&nbsp;</span>
                <span class="char" v-for="char in line[0]" v-html="char == ' ' ? '&nbsp;' : char"/>
                <div class="cursor"></div>
            </a>
        </template>
    </label>
</template>

<script setup>
    import gsap from 'gsap'
</script>

<script>
    export default {
        data() {
            return {
                lines: [
                    "",
                    "Loading website...",
                    "",
                    '<!DOCTYPE html>',
                    '<html>',
                    '<head>',
                    '    <meta charset="utf-8">',
                    '    <meta name="viewport" content="width=device-width, initial-scale=1">',
                    '    <title>DesignBot creative studio</title>',
                    '</head>',
                    "",
                    '<body>',
                    "    ____            _             ____        __",
                    "   / __ \\___  _____(_)___ _____  / __ )____  / /_",
                    "  / / / / _ \\/ ___/ / __ `/ __ \\/ __  / __ \\/ __/",
                    " / /_/ /  __(__  ) / /_/ / / / / /_/ / /_/ / /_", 
                    "/_____/\\___/____/_/\\__, /_/ /_/_____/\\____/\\__/",
                    "                  /____/",
                    "",
                    ["hello@designbot.nl", "mailto:hello@designbot.nl"],
                    "",
                    '</body>',
                    '</html>'
                ],
                activeLine: 0,
                typingLine: undefined,
                // speed: [44, 1440],
                speed: [5, 400],
                done: [],
                variable: '',
                input: ''

            }
        },

        created () {
            window.addEventListener('scroll', this.handleScroll);
        },

        unmounted () {
            window.removeEventListener('scroll', this.handleScroll);
        },

        mounted() {
            let lines = document.querySelectorAll('.line'),
                timeline = gsap.timeline(),
                that = this

            lines.forEach( (line, x) => {
                timeline.to(line, {
                    duration: 0,
                    onComplete: function() {
                        that.activeLine = x
                    }
                })

                timeline.to(line, {
                    duration: Math.random(500) / 1000,
                    onComplete: function() {
                        that.typingLine = x
                    }
                })

                let chars = line.querySelectorAll('.char')
                chars.forEach( (char, x) => {
                    timeline.to(char, {
                        duration: Number((that.speed[0] + Math.random(that.speed[1])) / 1000),
                        css: {
                            translateY: '0px',
                            opacity: 1, 
                            position: 'relative'
                        }
                    })
                })

                timeline.to(line, {
                    duration: 0,
                    onComplete: function() {
                        that.typingLine = undefined
                        that.done.push(x)
                    }
                })

                timeline.to(line, {
                    duration: (400 + Math.random(500)) / 1000,
                    onComplete: function() {
                        that.activeLine = x
                    }
                })
            })
        },

        methods: {
            update(event){
                // event.preventDefault()
                if (event.keyCode == 13) { 
                    this.input = ''
                    this.lines.push("$")
                    this.activeLine = this.lines.length - 1
                }

                // console.log(event)
                // this.lines[this.lines.length - 1] = this.lines[this.lines.length - 1] + event.target.value
            }
        },

        computed: {
            computedLines() {
                let original = this.lines
                let test = original.pop() + this.input
                console.log(test)
                original.push(test)
                return this.lines
            }
        }
    };
</script>
