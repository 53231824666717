<template>
    <div class="graph-fixture">
        <div class="graph-title">
            <div class="graph-tabs">
                <button v-for="(item, i) in data" :class="i == displayIndex ? 'active' : ''" @click="changedata(i)">
                    <svg class="left" viewBox="0 0 auto auto" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 16H0V0C0 8.83655 7.16333 16 16 16Z" fill="currentColor"/></svg>
                    <span>{{ item.name }}</span>
                    <span class="total">{{ item.total }}</span>
                    <svg class="right" viewBox="0 0 auto auto" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 0V16H0C8.83656 16 16 8.83656 16 0Z" fill="currentColor"/></svg>
                </button>
            </div>

            <div class="switch-view">
                <button v-for="(view, index) in styles" :class="style == view['value'] ? 'active' : ''" @click="changeStyle(view, index)">
                    <svg class="left" viewBox="0 0 auto auto" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 16H0V0C0 8.83655 7.16333 16 16 16Z" fill="currentColor"/></svg>
                    <svg v-if="view['value'] == 'point'" class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z"/></svg>
                    <svg v-else-if="view['value'] == 'bar'" class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M32 32c17.7 0 32 14.3 32 32V400c0 8.8 7.2 16 16 16H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H80c-44.2 0-80-35.8-80-80V64C0 46.3 14.3 32 32 32zM160 224c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32s-32-14.3-32-32V256c0-17.7 14.3-32 32-32zm128-64V320c0 17.7-14.3 32-32 32s-32-14.3-32-32V160c0-17.7 14.3-32 32-32s32 14.3 32 32zm64 32c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V224c0-17.7 14.3-32 32-32zM480 96V320c0 17.7-14.3 32-32 32s-32-14.3-32-32V96c0-17.7 14.3-32 32-32s32 14.3 32 32z"/></svg>
                    <svg class="right" viewBox="0 0 auto auto" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 0V16H0C8.83656 16 16 8.83656 16 0Z" fill="currentColor"/></svg>
                </button>
            </div>
        </div>

        <div class="graph-wrapper">
            <div class="graph-container" ref="graph">
                <div class="graph-scroll">
                    <div class="graph-canvas" ref="canvas" v-if="points.length" :style="{width: graphWidth + 'px'}">
                        <svg class="graph" v-show="line" preserveAspectRatio="none">
                            <path :d="d" fill="none" stroke-width="1"/>
                        </svg>

                        <div class="bars-wrap">
                            <div class="bars" :class="[style, activePoint == point ? 'active' : '']" v-for="point in points" :style="{left: point.x + 'px'}" @click="activePoint = point">
                                <div class="item" :class="style" :style="{'--postop': point.y + 'px'}">
                                    <span>{{ point.label }}</span>
                                </div>
                                <div class="name">{{ point.name }}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="y-grid">
                    <div class="line" v-for="yPos in ceilPos" :style="{bottom: Math.round(yPos[0]) + 'px'}">
                        <span>{{ yPos[1] }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import {gsap, TweenMax, TweenLite} from 'gsap'
    import Draggable from "gsap/Draggable"
    gsap.registerPlugin(Draggable)
</script>

<script>
    export default {
        props: ['data'],

        data() {
            return {
                points: [],
                d: '',
                ceilPos: [],
                maxSteps: 10,
                activePoint: 0,
                index: 0,
                displayIndex: 0,
                userStyle: false,
                userLine: false,
                // styles: ['bar', 'point', false],
                styles: [{
                    'value': 'bar',
                    'label': 'Bars'
                }, {
                    'value': 'point',
                    'label': 'Points'
                }
                // , {
                //     'value': false,
                //     'label': 'Reset'
                // }
                ],
                swipeSpeed: 0.2
            }
        },

        mounted() {
            this.reload()
        },

        created () {
            window.addEventListener('resize', this.handleResize);
        },

        unmounted () {
            window.removeEventListener('resize', this.handleResize);
        },

        watch: { 
            points: function(newVal, oldVal) {
                let that = this,
                    offf = 40,
                    step = (this.$refs.graph.offsetWidth - 40 - 40) / (this.currentData.length - 1),
                    dstp = Math.min(Math.max(step, (this.line ? 100 : 0)), 400)

                setTimeout(function(){
                    Draggable.create(that.$refs.canvas, {
                        type: 'x',
                        lockAxis: true,
                        maxDuration: 1,
                        edgeResistance: 0.6,
                        dragResistance: 0,
                        allowEventDefault: true, 
                        onDragEnd: function(value) {
                            let offset = -(document.querySelector('.graph-canvas').offsetWidth - document.querySelector('.graph-scroll').offsetWidth),
                                jo = offset

                            if (this.x > offf) {
                                TweenLite.to(this.target, 0.4, {
                                    css: {
                                        translateX: offf + 'px'
                                    },
                                    ease: "back.out(0.7)", 
                                    
                                })
                            } else if (this.x < jo - offf) {
                                TweenLite.to(this.target, 0.4, {
                                    css: {
                                        translateX: (jo - offf) + 'px'
                                    },
                                    ease: "back.out(0.7)", 
                                })
                            } 
                            // else {
                            //     TweenLite.set(this.target, {
                            //         css: {
                            //             translateX: Math.round(value / dstp) * dstp + 'px'
                            //         },
                            //     })
                            // }
                        },
                        // snap: function(value) {
                        //     TweenLite.to(this.target, 0.32, {x:Math.round(value / dstp) * dstp, y:this.y});
                        // },
                    })
                }, 200); 
            }
        },

        methods: {
            changeStyle(style, index) {
                if (index == this.styleIndex) return

                let up = this.styleIndex > index,
                    slideOut = 80

                this.styleIndex = index
                gsap.to(this.$refs.graph, this.swipeSpeed, {
                    ease: 'expo.in', 
                    y: up ? slideOut : -slideOut,
                    scale: 0.9,
                    opacity: 0,
                    onComplete: () => {
                        this.userStyle = style['value']
                        this.reload()
                        gsap.set(this.$refs.graph, {
                            y: up ? -slideOut : slideOut,
                            scale: 0.9,
                            opacity: 0,
                        })
                        gsap.to(this.$refs.graph, this.swipeSpeed, {
                            ease: 'expo.out', 
                            y: 0,
                            scale: 1,
                            opacity: 1,
                        })
                    }
                })
            },

            changedata (index) {
                if (index == this.displayIndex) return
                let up = this.displayIndex > index,
                    slideOut = 400

                this.displayIndex = index
                this.$refs.graph.style.transformOrigin = 'bottom center'
                gsap.to(this.$refs.graph, this.swipeSpeed, {
                    ease: 'expo.in', 
                    x: up ? slideOut : -slideOut,
                    scale: 0.9,
                    opacity: 0,
                    onComplete: () => {
                        this.index = index
                        this.userStyle = false
                        this.userLine = false
                        this.reload()
                        gsap.set(this.$refs.graph, {
                            x: up ? -slideOut : slideOut,
                            scale: 0.9,
                            opacity: 0,
                        })
                        gsap.to(this.$refs.graph, this.swipeSpeed, {
                            ease: 'expo.out', 
                            x: 0,
                            scale: 1,
                            opacity: 1,
                            onComplete: () => {
                                this.$refs.graph.style.transformOrigin = 'bottom left'
                            }
                        })
                    }
                })
            },

            handleResize (event) {
                this.reload()
            },

            reload() {
                this.points = []
                this.ceilPos = []
                this.d = ''
                let data = '',
                    dataArr = [],
                    graph = this.$refs.graph,
                    step = (graph.offsetWidth - 40 - 40) / (this.currentData.length - 1),
                    dstp = Math.min(Math.max(step, (this.line ? 100 : 100)), (this.line ? 240 : 180))

                let offsf = 48,
                    ehgti = graph.offsetHeight - offsf

                this.currentData.forEach((item, x) => {
                    let xPos = dstp * x,
                        yPos = (ehgti - (ehgti) * (item.y / 100))

                    this.points.push({
                        name: item.name,
                        label: item.label,
                        x: xPos, 
                        y: yPos
                    })

                    dataArr.push(xPos)
                    dataArr.push(yPos)
                })

                let ceilsteps = Number(Math.round(this.ceil / this.maxSteps)),
                    steops = []

                for (var i = this.maxSteps; i >= 0; i--) {
                    steops.push(i * ceilsteps)
                }

                for (var x = this.ceil; x >= 0; x--) {
                    if (steops.includes(x)) {
                        this.ceilPos.push([ehgti / this.ceil * x, x])
                    }
                }

                this.points.slice(0, -2)
                this.d = this.cardinalSpline(dataArr, this.data[this.index]['tension'])
            },

            cardinalSpline(data, k) {

                if (k == null) k = 1;

                var size = data.length;
                var last = size - 4;    

                var path = `M${data[0]} ${data[1]} C`;

                for (var i = 0; i < size - 2; i +=2) {
                    var x0 = i ? data[i - 2] : data[0];
                    var y0 = i ? data[i - 1] : data[1];

                    var x1 = data[i + 0];
                    var y1 = data[i + 1];

                    var x2 = data[i + 2];
                    var y2 = data[i + 3];

                    var x3 = i !== last ? data[i + 4] : x2;
                    var y3 = i !== last ? data[i + 5] : y2;

                    var cp1x = x1 + (x2 - x0) / 6 * k;
                    var cp1y = y1 + (y2 - y0) / 6 * k;

                    var cp2x = x2 - (x3 - x1) / 6 * k;
                    var cp2y = y2 - (y3 - y1) / 6 * k;

                    path += ` ${cp1x} ${cp1y} ${cp2x} ${cp2y} ${x2} ${y2}`;
                }

                return path;
            }
        },

        computed: {
            graphWidth() {
                return this.points[this.points.length - 1].x
            },

            currentData() {
                return this.data[this.index]['data']
            },

            ceil() {
                return this.data[this.index]['ceil']
            },

            style() {
                return this.userStyle ? this.userStyle : this.data[this.index]['style']
            },

            line() {
                return this.userStyle ? this.userStyle == 'point' : this.data[this.index]['line']
            },

            styleIndex() {
                // return this.styles.indexOf(this.style);
                let that = this
                return this.styles.findIndex(function(style) {
                  return style.value == that.style
                });

            }
        }
    };
</script>
