'use strict'

export function init () {
	console.log('init cookies')
}

export function set (name, value, expirationDays) {
    let date = new Date()
    date.setTime(date.getTime() + (expirationDays * 24 * 60 * 60 * 1000))
    const expires = "expires=" + date.toUTCString()
    document.cookie = name + "=" + value + "; " + expires + "; path=/"
}

export function get (name) {
    const setName = name + "="
    const cDecoded = decodeURIComponent(document.cookie)
    const cArr = cDecoded .split('; ')
    let res
    cArr.forEach(val => {
        if (val.indexOf(setName) === 0) res = val.substring(setName.length)
    })
    return res
}