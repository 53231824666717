import { createApp } from 'vue'
import gsap from 'gsap'

// // Vue components 
import Graph from './components/Graph.vue'
import CommandLine from './components/CommandLine.vue'

// Modules
import * as cookies from './modules/cookies'
import ClickOutside from './modules/click-outside.js';

document.addEventListener("DOMContentLoaded", e => {
	console.log(cookies.get('timezone'))
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // e.g. "America/New_York"
    document.cookie = "django_timezone=" + timezone;

	let components = document.querySelectorAll('.component')

    if (components) {
		components.forEach(component => {
			let app = createApp()

			app.directive('click-outside', ClickOutside);

			// Register components 
			app.component('Graph', Graph)
			app.component('CommandLine', CommandLine)

			// Register uses
			// app.config.globalProperties.translations = 'translations'

			// Start Vue app
			app.mount(component)
		})
	}
})